.home-card {
  @include flex(column, nowrap, flex-start, flex-end);
  height: 325px;
  position: relative;
  padding: 0 0.5em 1em 1.5em;
  z-index: 0;

  &:nth-child(1), &:nth-child(2) {
    height: 400px;
  }

  .background {
    @include position(absolute, 0 0 0 0);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -2;
  }

  .overlay {
    @include position(absolute, 0 0 0 0);
    z-index: -1;
    mix-blend-mode: multiply;

    &.induction-heating {
      background-color: color("san-juan");
    }

    &.infrared-curing {
      background-color: color("burnt-umber");
    }

    &.electric-forklifts {
      background-color: color("dingley");
    }

    &.pipeline {
      background-color: color("pesto");
    }

    &.electric-vehicles {
      background-color: color("bondiblue");
    }

    // for IE11
    @media screen and (-ms-high-contrast: none) {
      opacity: 0.8;
    }

    // for Edge
    @supports (-ms-ime-align: auto) {
      opacity: 0.8;
    }
  }

  .video-play-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.large .video-play-icon { top: 25%; }
  &.small .video-play-icon { top: 15%; }

  > a {
    @include position(absolute, 0 0 0 0);
    border-bottom: 0;
    z-index: 1;
  }

  .topic {
    margin-bottom: 0.7em;
    background-color: color("nevada"); // temp until we know the colors for each topic
    color: color("white");
    border: 1px solid color("white");

    &.induction-heating {
      background-color: color("chambray");
    }

    &.infrared-curing {
      background-color: color("medium-carmine");
    }

    &.forklifts {
      background-color: color("asparagus");
    }

    &.pipelines {
      background-color: color("fuel-yellow");
    }

    &.electric-vehicles {
      background-color: color("bondiblue");
    }
  }

  .title {
    // max-width for dumb IE11
    max-width: 100%;
    height: 99px;
    overflow: hidden;
  }

  // intentional pixel line-heights for line-clamp polyfill
  &.large .title { line-height: 46px; }
  &.small .title { line-height: 33px; }
}
