/**
*    ___   ___    _  _  ___ _____   __  __  ___  ___ ___ _____   __
*   |   \ / _ \  | \| |/ _ \_   _| |  \/  |/ _ \|   \_ _| __\ \ / /
*   | |) | (_) | | .` | (_) || |   | |\/| | (_) | |) | || _| \ V /
*   |___/ \___/  |_|\_|\___/ |_|   |_|  |_|\___/|___/___|_|   |_|
* This file is generated with SASS and will be overwritten
*/
/**
 * THEME COLORS
 */
/**
 * COLOR MAP - light and dark gray are wireframes
 */
/**
 * FONT IMPORTS AND SETTINGS - all but admin font IES specific
 */
/**
 * PROPERTIES
 */
/**
 * GLOBAL SETTINGS
 */
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; }

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

/*
 * Disable pointer events on SVG because Edge and IE11 do
 * not support clickable SVGs
 */
svg {
  pointer-events: none; }

/* =Typography
----------------------------------------------- */
@font-face {
  font-family: 'DIN';
  src: url("../../fonts/D-DIN.woff2") format("woff2"), url("../../fonts/D-DIN.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'DIN';
  src: url("../../fonts/D-DIN-Italic.woff2") format("woff2"), url("../../fonts/D-DIN-Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'DIN';
  src: url("../../fonts/D-DIN-Bold.woff2") format("woff2"), url("../../fonts/D-DIN-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

a {
  border-bottom: 1px solid transparent;
  text-decoration: none;
  color: inherit;
  transition: border-color 300ms; }

li a.active {
  color: inherit; }

.typography.light {
  color: #686a6c; }

.typography.light a {
  color: #376291; }

.typography.light a.is-active,
.no-touchevents .typography.light a:hover {
  border-color: #376291; }

.typography.dark {
  color: #fff; }

.typography.dark a {
  color: #fff;
  border-color: transparent;
  border-width: 4px; }

.typography.dark a.is-active,
.no-touchevents .typography.dark a:hover {
  border-color: #c22220; }

ul {
  padding-left: 1.3em; }

li {
  padding-bottom: 1em; }

div, p, small, h1, h2, h3, h4, h5, h6, li, button, svg text {
  color: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p, li, div {
  font-family: "DIN", sans-serif; }

p, li {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 0.7em;
  line-height: 1.4em; }
  p:last-child {
    margin-bottom: 0; }

small {
  display: block;
  font-size: 0.64em; }

h1, h2, h3, h4, h5, h6 {
  font-family: "DIN", sans-serif;
  margin: 0;
  font-weight: 600; }

h1 {
  font-size: 2.5rem;
  font-weight: 400; }

h2 {
  font-size: 2.375rem; }

h3 {
  font-size: 1.75rem; }

h4 {
  font-size: 1.375rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 0.75rem; }

button, .button {
  font-family: "DIN", sans-serif; }

input {
  font-family: "DIN", sans-serif; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #fff; }

.no-margin {
  margin-top: 0;
  margin-bottom: 0; }

.no-line-height {
  line-height: 0; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.w-xlight {
  font-weight: 200; }

.w-light {
  font-weight: 300; }

.w-regular {
  font-weight: 400; }

.w-semibold {
  font-weight: 600; }

.w-bold {
  font-weight: 700; }

.w-xbold {
  font-weight: 800; }

.uppercase {
  text-transform: uppercase; }

.lowercase {
  text-transform: lowercase; }

.capitalize {
  text-transform: capitalize; }

.italic {
  font-style: italic; }

.underline {
  text-decoration: underline; }

.sub-script {
  position: relative;
  top: 4px;
  font-size: 60%; }

.text-shadow {
  text-shadow: 0 5px 12px rgba(0, 0, 0, 0.45); }

.text-shadow-alt {
  text-shadow: 0 5px 8px rgba(0, 0, 0, 0.45); }

#admin-menu {
  background: #101010 !important; }
  #admin-menu li {
    font-family: "Verdana", "Geneva", sans-serif;
    font-size: 1em; }
  #admin-menu a {
    color: #fafafa !important;
    font-weight: 600; }

ul.tabs.primary {
  display: flex;
  align-items: center; }
  ul.tabs.primary li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 1em;
    font-family: "Verdana", "Geneva", sans-serif;
    font-size: 0.8em; }
  ul.tabs.primary a {
    padding: 0.5em 2em;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600; }

ul.contextual-links li {
  font-family: "Verdana", "Geneva", sans-serif;
  font-size: 1em;
  font-weight: 600; }

html,
body {
  height: 100%;
  width: 100%; }

body {
  overflow-x: hidden; }
  body > svg {
    display: none; }

img {
  max-width: 100%;
  height: auto; }

#first-time {
  display: none; }

.page-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%; }

.admin-menu .region-sidebar-left {
  top: 29px; }

input {
  outline: 0; }

.youtube-embed-wrapper,
.videodetector {
  height: 0;
  position: relative;
  padding-top: 0.25em;
  padding-bottom: 56.25%;
  margin-bottom: 2em; }
  .youtube-embed-wrapper iframe,
  .videodetector iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.page-user .page-wrapper {
  flex-direction: column; }

table {
  margin-top: 1em; }
  table tbody {
    border-top: 0; }
  table td {
    display: block;
    padding: 0 2em;
    vertical-align: top; }
    @media (min-width: 520px) {
      table td {
        display: table-cell; } }
    table td ul {
      margin: 0; }

.block-common-cta {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  background-color: #fff; }
  .block-common-cta img {
    max-width: 100%; }
  .block-common-cta .cta-header {
    width: 100%;
    padding: 0.25em 0;
    background-color: #86888A;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center; }
  .block-common-cta .cta-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0.5em;
    z-index: 0;
    overflow: hidden; }
  .block-common-cta .cta-background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5em;
    object-fit: cover;
    z-index: -1; }
  .block-common-cta .cta-image {
    height: 100%;
    object-fit: contain;
    font-family: "object-fit: contain;"; }
    .block-common-cta .cta-image.cover {
      object-fit: cover;
      font-family: "object-fit: cover;"; }

.block-energy-conversion {
  background-color: #fff; }
  .block-energy-conversion .info {
    margin: 1em auto;
    padding: 0 1em; }
  .block-energy-conversion #energy-title {
    position: relative;
    display: flex;
    align-items: flex-end;
    background-image: url("/sites/all/themes/aephub/img/energy-conversion.png");
    padding: 1em; }
    @media (min-width: 690px) {
      .block-energy-conversion #energy-title {
        height: 224px; } }
    @media (min-width: 1100px) {
      .block-energy-conversion #energy-title {
        height: 255px;
        padding: 1em 1.5em; } }
    .block-energy-conversion #energy-title #energy-tag {
      margin-bottom: 0;
      color: #fff;
      font-size: 1.25rem;
      font-style: italic;
      font-weight: 600; }
    .block-energy-conversion #energy-title h1 {
      width: 50%;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600; }
      @media (min-width: 690px) {
        .block-energy-conversion #energy-title h1 {
          font-size: 4rem;
          line-height: 0.875em; } }
    .block-energy-conversion #energy-title #energy-icon {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      width: 4em;
      opacity: 0.75; }
      @media (min-width: 690px) {
        .block-energy-conversion #energy-title #energy-icon {
          width: 5em; } }

#block-menu-menu-footer .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0; }
  #block-menu-menu-footer .menu .leaf {
    margin: 0 0.75em;
    padding: 0;
    font-size: 0.75rem;
    font-weight: 600;
    list-style: none; }

#menu-link-home,
#contact-link-home {
  position: relative;
  padding: 1.6em 1.2em 1.5em 1.1em; }
  #menu-link-home a,
  #contact-link-home a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 9; }
  #menu-link-home span,
  #contact-link-home span {
    font-size: 1.375rem;
    font-weight: 600; }

#menu-link-home {
  border-top: 1px solid #686a6c; }

#contact-link-home {
  border-bottom: 1px solid #686a6c; }

#block-block-10 {
  flex-grow: 1; }

#block-system-main-menu .menu {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin: 0;
  padding: 0; }
  #block-system-main-menu .menu .leaf {
    list-style: none; }
    #block-system-main-menu .menu .leaf a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      z-index: 9; }

#block-system-main-menu li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 1em 1.2em 1em 0.8em;
  border-bottom: 1px solid #686a6c;
  margin-left: 0;
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: uppercase; }
  #block-system-main-menu li:first-child {
    border-top: 1px solid #686a6c; }
  #block-system-main-menu li svg {
    height: 1em;
    width: 1em;
    fill: #fff;
    transform: translateX(0);
    transition: transform 300ms; }
  #block-system-main-menu li.is-active {
    background-color: #3e3f41; }
  #block-system-main-menu li:hover svg {
    transform: translateX(0.5em); }

#block-system-main-menu li span,
#menu-link-home span,
#contact-link-home span {
  border-bottom: 4px solid transparent;
  text-transform: uppercase;
  transition: border-color 300ms; }

#block-system-main-menu li:hover span,
#menu-link-home:hover span,
#contact-link-home:hover span {
  border-color: #c22220; }

.page-user #block-system-main,
.node-webform {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; }
  .page-user #block-system-main form,
  .node-webform form {
    padding-top: 6em; }
  .page-user #block-system-main form > div,
  .node-webform form > div {
    display: flex;
    flex-flow: column nowrap;
    border: 2px solid #376291;
    padding: 2em; }
    .page-user #block-system-main form > div h2,
    .node-webform form > div h2 {
      order: -1; }
    .page-user #block-system-main form > div input:not([type=checkbox]),
    .node-webform form > div input:not([type=checkbox]) {
      width: 100%; }
    .page-user #block-system-main form > div input[type=submit],
    .node-webform form > div input[type=submit] {
      width: 30%; }

.basic-card {
  display: flex;
  flex-flow: column nowrap;
  width: 390px;
  max-width: 100%; }
  .basic-card .top {
    display: flex;
    align-items: flex-end;
    height: 192px;
    position: relative;
    padding: 1.25em;
    z-index: 0; }
    .basic-card .top a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 0; }
  .basic-card .bottom {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.25em;
    height: 145px;
    background-color: #fff; }
  .basic-card .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1; }
  .basic-card .video-play-icon {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%); }
  .basic-card .title {
    max-width: 100%;
    height: 82px;
    line-height: 27px;
    overflow: hidden; }
    .basic-card .title > * {
      font-weight: 400; }
  .basic-card .tag {
    font-weight: 600; }

button,
.button,
.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8em 1.2em;
  border: 0;
  background-color: #376291;
  font-size: 1.125em;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 300ms; }
  button span,
  .button span,
  .button-primary span {
    flex-grow: 1; }
  button svg,
  .button svg,
  .button-primary svg {
    height: 1em;
    width: 1em;
    margin-left: 0.25em;
    fill: #fff; }
  button.white,
  .button.white,
  .button-primary.white {
    color: #fff !important; }
  button.hollow,
  .button.hollow,
  .button-primary.hollow {
    padding: 0.8em 4em;
    border: 1px solid #92262a;
    background-color: #fff;
    color: #92262a; }
  button.topic,
  .button.topic,
  .button-primary.topic {
    padding: 0.6em 0.7em;
    background-color: #686a6c;
    color: #fff;
    border: 1px solid #fff;
    font-size: 1em;
    text-transform: none; }
    button.topic.induction-heating,
    .button.topic.induction-heating,
    .button-primary.topic.induction-heating {
      background-color: #2a4b6f; }
    button.topic.infrared-curing,
    .button.topic.infrared-curing,
    .button-primary.topic.infrared-curing {
      background-color: #92262a; }
    button.topic.electric-forklifts,
    .button.topic.electric-forklifts,
    .button-primary.topic.electric-forklifts {
      background-color: #607a43; }
    button.topic.pipeline,
    .button.topic.pipeline,
    .button-primary.topic.pipeline {
      background-color: #7d6b32; }
    button.topic.electric-vehicles,
    .button.topic.electric-vehicles,
    .button-primary.topic.electric-vehicles {
      background-color: #00a7b5; }
  button.filter,
  .button.filter,
  .button-primary.filter {
    justify-content: space-between;
    width: 270px;
    padding: 0.8em 0.7em;
    border: 0;
    background-color: #fff;
    color: #686a6c;
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: none; }
    button.filter svg,
    .button.filter svg,
    .button-primary.filter svg {
      height: 1.25em;
      width: 1.25em;
      margin-left: 0;
      fill: #686a6c; }

.no-touchevents .button:not(.topic):not(.filter):not(.hollow):hover {
  background-color: #ba3136; }

.no-touchevents .button.hollow:hover {
  background-color: #92262a;
  color: #fff; }

.home-card {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  height: 325px;
  position: relative;
  padding: 0 0.5em 1em 1.5em;
  z-index: 0; }
  .home-card:nth-child(1), .home-card:nth-child(2) {
    height: 400px; }
  .home-card .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -2; }
  .home-card .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    mix-blend-mode: multiply; }
    .home-card .overlay.induction-heating {
      background-color: #2a4b6f; }
    .home-card .overlay.infrared-curing {
      background-color: #92262a; }
    .home-card .overlay.electric-forklifts {
      background-color: #607a43; }
    .home-card .overlay.pipeline {
      background-color: #7d6b32; }
    .home-card .overlay.electric-vehicles {
      background-color: #00a7b5; }
    @media screen and (-ms-high-contrast: none) {
      .home-card .overlay {
        opacity: 0.8; } }
    @supports (-ms-ime-align: auto) {
      .home-card .overlay {
        opacity: 0.8; } }
  .home-card .video-play-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .home-card.large .video-play-icon {
    top: 25%; }
  .home-card.small .video-play-icon {
    top: 15%; }
  .home-card > a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 0;
    z-index: 1; }
  .home-card .topic {
    margin-bottom: 0.7em;
    background-color: #686a6c;
    color: #fff;
    border: 1px solid #fff; }
    .home-card .topic.induction-heating {
      background-color: #376291; }
    .home-card .topic.infrared-curing {
      background-color: #ad3733; }
    .home-card .topic.forklifts {
      background-color: #6e9147; }
    .home-card .topic.pipelines {
      background-color: #ebb220; }
    .home-card .topic.electric-vehicles {
      background-color: #00a7b5; }
  .home-card .title {
    max-width: 100%;
    height: 99px;
    overflow: hidden; }
  .home-card.large .title {
    line-height: 46px; }
  .home-card.small .title {
    line-height: 33px; }

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
  transition: opacity 300ms; }
  .modal.is-active {
    opacity: 1;
    pointer-events: auto; }
  .modal .modal-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 0);
    z-index: -1; }
  .modal .modal-copy {
    height: 40%;
    width: 40%;
    position: relative;
    background-color: #fff;
    transform: scale(0.75, 0.75);
    transition: transform 300ms; }
  .modal.is-active .modal-copy {
    transform: scale(1, 1); }
  .modal .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .modal .modal-close svg {
      height: 3em;
      width: 3em;
      fill: #686a6c; }

.social-icons {
  display: flex; }
  .social-icons > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3em;
    width: 3em;
    position: relative;
    margin-right: 0.6em;
    border: 1px solid #ff00d2;
    background-color: #fff;
    transition: background-color 300ms; }
    .social-icons > div:last-child {
      margin-right: 0; }
  .social-icons svg {
    height: 1.2em;
    width: 1.2em;
    fill: #ff00d2;
    transition: fill 300ms; }
  .social-icons .youtube svg {
    height: 1.4em;
    width: 1.4em; }
  .social-icons a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 0; }

.no-touchevents .social-icons > div:hover {
  background-color: #ff00d2; }
  .no-touchevents .social-icons > div:hover svg {
    fill: #fff; }

.video-play-icon {
  padding: 0.5em;
  border-radius: 50%;
  border: 2px solid #fff;
  fill: #fff; }

.home-card .video-play-icon {
  height: 90px;
  width: 90px; }

.basic-card .video-play-icon {
  height: 80px;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.2); }

.node-basic-post {
  display: flex;
  flex-flow: column nowrap; }
  @media (min-width: 1100px) {
    .node-basic-post {
      flex-direction: row;
      padding-right: 2em; } }
  @media (min-width: 1400px) {
    .node-basic-post {
      padding-right: 4em; } }
  .node-basic-post .main {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 0 1em;
    background-color: #fff; }
    @media (min-width: 960px) {
      .node-basic-post .main {
        padding: 0 2em 0 4em; } }
    @media (min-width: 1100px) {
      .node-basic-post .main {
        flex: 1 1 0; } }
  .node-basic-post .copy {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3em; }
  .node-basic-post .field {
    margin-top: 2em; }
  .node-basic-post .field-label {
    margin-bottom: 1em; }
  .node-basic-post .field-name-field-date-published {
    align-self: flex-end;
    margin: 0 2em 1em 0;
    font-weight: 300; }
  .node-basic-post .blog-content {
    max-width: 100%; }
    @media (min-width: 450px) {
      .node-basic-post .blog-content {
        display: flex; } }
  @media (min-width: 450px) {
    .node-basic-post .attached-doc {
      margin-left: 220px; } }
  .node-basic-post .authors {
    width: 70%;
    max-width: 100%;
    margin: 0 0 1em; }
    @media (min-width: 450px) {
      .node-basic-post .authors {
        margin-right: 1em; }
        .node-basic-post .authors .field {
          margin-top: 0.4em; }
        .node-basic-post .authors .field-name-field-author-image {
          width: 220px;
          max-width: 100%; } }
    @media (min-width: 690px) {
      .node-basic-post .authors {
        width: 35%; } }
    @media (min-width: 960px) {
      .node-basic-post .authors {
        width: 38%;
        max-width: 250px;
        flex-direction: row; } }
  .node-basic-post .field-name-body {
    max-width: 100%;
    margin-top: 0; }
  .node-basic-post .field-name-field-topic,
  .node-basic-post .field-name-field-media-type {
    display: none; }
  .node-basic-post .share {
    flex-basis: 4em;
    display: flex;
    margin-top: 1em; }
    .node-basic-post .share > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3em;
      width: 3em;
      position: relative;
      margin-right: 0.5em;
      background-color: #686a6c;
      cursor: pointer;
      transition: transform 300ms;
      border-radius: 1em; }
      .node-basic-post .share > div:last-child {
        margin-right: 0; }
      @media (min-width: 1100px) {
        .node-basic-post .share > div {
          height: 2em;
          width: 2em;
          border-radius: .7em; } }
      .node-basic-post .share > div svg {
        height: 2em;
        width: 2em;
        fill: #fff; }
        @media (min-width: 1100px) {
          .node-basic-post .share > div svg {
            height: 1.5em;
            width: 1.5em; } }
      .node-basic-post .share > div a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 0 !important;
        background-color: #c22220;
        border-radius: 1em; }
        @media (min-width: 1100px) {
          .node-basic-post .share > div a {
            border-radius: .7em; } }
      .node-basic-post .share > div:first-child {
        z-index: 1; }
      .node-basic-post .share > div:nth-child(2) {
        transform: translateX(-100%) translateX(-0.5em); }
      .node-basic-post .share > div:nth-child(3) {
        transform: translateX(-200%) translateX(-1em); }
      .node-basic-post .share > div:nth-child(4) {
        transform: translateX(-300%) translateX(-1.5em); }
      .node-basic-post .share > div:nth-child(5) {
        transform: translateX(-400%) translateX(-2em); }
      .node-basic-post .share > div:nth-child(6) {
        transform: translateX(-500%) translateX(-2.5em); }
      .node-basic-post .share > div:nth-child(7) {
        transform: translateX(-600%) translateX(-3em); }
    .node-basic-post .share.is-active > div {
      transform: translateX(0); }

.node-page {
  display: flex; }
  @media (min-width: 960px) {
    .node-page {
      padding-right: 4em; } }
  .node-page .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 0 2em 3em;
    background-color: #fff; }
    @media (min-width: 960px) {
      .node-page .content {
        padding: 0 2em 3em 4em; } }
    .node-page .content h2 {
      margin: 1em 0; }

.node-social-feed .page-title {
  padding: 2.5em 5em 2em;
  background-color: #fff;
  font-size: 1.125rem; }

.node-social-feed .content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0 1em;
  background-color: #686a6c; }
  @media (min-width: 960px) {
    .node-social-feed .content {
      padding: 0 4em; } }

.node-social-feed .block-common-cta {
  width: 390px;
  height: calc(192px + 145px);
  margin: 1em; }

.node-social-feed .basic-card {
  margin: 1em; }

.node-social-feed .paginator {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1em; }
  .node-social-feed .paginator a {
    border-bottom: 0; }
    .node-social-feed .paginator a.disabled {
      pointer-events: none; }
      .node-social-feed .paginator a.disabled .button {
        background-color: #686a6c; }
  .node-social-feed .paginator .button {
    margin-bottom: 1em; }
  @media (min-width: 690px) {
    .node-social-feed .paginator {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      width: 70%;
      margin: 1em 0; } }

.region-bottom-nowrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.region-content {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: 60px; }
  @media (min-width: 960px) {
    .region-content {
      margin-top: 0;
      margin-left: 272px; } }
  .region-content .block-system {
    flex-grow: 1;
    background-color: #686a6c; }
    .region-content .block-system .content {
      background: #fff; }

.page-user .region-content,
.page-user .view-content,
.view-basic-lists .region-content,
.view-basic-lists .view-content {
  min-height: 100vh; }

.region-header {
  height: 60px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #4e5052;
  z-index: 9; }
  @media (min-width: 960px) {
    .region-header {
      display: none; } }
  .region-header .block, .region-header .content {
    height: 100%;
    width: 100%; }
  .region-header .content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .region-header .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    position: relative;
    margin-left: 1em;
    cursor: pointer; }
    .region-header .hamburger .lines,
    .region-header .hamburger .lines::before,
    .region-header .hamburger .lines::after {
      width: 100%;
      height: 3px;
      background-color: #fff; }
    .region-header .hamburger .lines::before, .region-header .hamburger .lines::after {
      content: "";
      position: absolute;
      left: 0; }
    .region-header .hamburger .lines::before {
      top: 8px; }
    .region-header .hamburger .lines::after {
      top: 24px; }
  .region-header .close {
    display: none;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    position: relative;
    margin-left: 1em;
    cursor: pointer; }
    .region-header .close .lines {
      height: 100%;
      width: 100%; }
      .region-header .close .lines::before, .region-header .close .lines::after {
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: #fff; }
      .region-header .close .lines::before {
        transform: rotate(-45deg); }
      .region-header .close .lines::after {
        transform: rotate(45deg); }
  .region-header.is-open .hamburger {
    display: none; }
  .region-header.is-open .close {
    display: flex; }
  .region-header .logo {
    padding-right: 0.5em; }
    .region-header .logo a {
      border: 0; }
    .region-header .logo img {
      width: 132px;
      height: 40px; }

.region-sidebar-left {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 272px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: 60px;
  background-color: #4e5052;
  color: #fff;
  z-index: 29;
  transform: translateX(-100%);
  transition: transform 300ms; }
  @media (min-width: 960px) {
    .region-sidebar-left {
      margin-top: 0;
      border-right: 1px solid #686a6c;
      transform: translateX(0); } }
  .region-sidebar-left.is-active {
    transform: translateX(0); }
  .region-sidebar-left .block {
    width: 100%; }
  @media (min-width: 960px) {
    .region-sidebar-left #block-block-4 {
      margin-bottom: 1.5em; } }
  .region-sidebar-left #block-block-4 .logo {
    width: 175px;
    height: 53px;
    margin: 1.5em 0 0 1.5em;
    display: none; }
    .region-sidebar-left #block-block-4 .logo a {
      border-bottom: 0; }
    @media (min-width: 960px) {
      .region-sidebar-left #block-block-4 .logo {
        display: inline; } }
    .region-sidebar-left #block-block-4 .logo img {
      width: 30%;
      margin-left: .5em; }
  .region-sidebar-left .block-search {
    height: 2.5em;
    position: relative;
    border: 2px solid #86888A;
    background-color: #686a6c;
    border-radius: 1em;
    margin: 2em .5em;
    width: 90%; }
    @media (min-width: 960px) {
      .region-sidebar-left .block-search {
        border-top: 0; } }
    .region-sidebar-left .block-search .content,
    .region-sidebar-left .block-search form,
    .region-sidebar-left .block-search form > div,
    .region-sidebar-left .block-search .form-text {
      height: 100%;
      width: 100%; }
    .region-sidebar-left .block-search form > div {
      display: flex;
      align-items: center;
      padding-left: 1.5em; }
    .region-sidebar-left .block-search .form-item {
      flex-grow: 1;
      margin: 0; }
      .region-sidebar-left .block-search .form-item input {
        font-weight: 600;
        text-transform: uppercase; }
    .region-sidebar-left .block-search .form-text {
      border: 0; }
    .region-sidebar-left .block-search .icon {
      margin-right: 1em;
      pointer-events: none; }
      .region-sidebar-left .block-search .icon svg {
        height: 1.35em;
        width: 1.35em; }
      .region-sidebar-left .block-search .icon svg,
      .region-sidebar-left .block-search .icon use > svg {
        fill: #fff;
        transition: fill 300ms; }
    .region-sidebar-left .block-search.is-active .icon {
      pointer-events: auto;
      cursor: pointer; }
      .region-sidebar-left .block-search.is-active .icon svg,
      .region-sidebar-left .block-search.is-active .icon use > svg {
        fill: #fff; }
  .region-sidebar-left #block-menu-menu-footer {
    padding-bottom: 1em; }

.region-sidebar-right {
  padding: 0.75em 1em; }
  @media (min-width: 1100px) {
    .region-sidebar-right {
      padding-right: 0; } }
  @media (min-width: 1100px) {
    .region-sidebar-right {
      width: 345px; } }
  @media (min-width: 1400px) {
    .region-sidebar-right {
      width: auto; } }
  .region-sidebar-right .block-views {
    width: 100%; }
  .region-sidebar-right .block-common-cta {
    width: 390px;
    max-width: 100%;
    margin-bottom: 1.5em; }
  .region-sidebar-right .basic-card {
    margin: 0 0 1em 0; }
  .region-sidebar-right .label {
    margin-bottom: 0.5em;
    font-size: 1.625rem;
    text-transform: uppercase; }

.region-subnav {
  width: 272px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: 60px;
  z-index: 99;
  transform: translateX(-100%);
  transition: transform 300ms; }
  @media (min-width: 960px) {
    .region-subnav {
      margin-top: 0;
      z-index: 19;
      transform: translateX(0); } }
  .region-subnav .block-views {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 4em 1.75em 0;
    background-color: #3e3f41;
    transform: translateX(0);
    transition: transform 300ms;
    overflow-y: auto;
    text-transform: uppercase; }
    @media (min-width: 960px) {
      .region-subnav .block-views {
        padding: 14.5em 1.75em 0; } }
    .region-subnav .block-views.is-active {
      transform: translateX(100%);
      z-index: 99; }
    .region-subnav .block-views .view-content a,
    .region-subnav .block-views .view-footer a {
      font-family: inherit;
      font-size: 1.125rem;
      line-height: 1.222em; }
    .region-subnav .block-views .view-content > div,
    .region-subnav .block-views .view-footer {
      margin-bottom: 1.5em; }
    .region-subnav .block-views .submenu-back {
      height: 2em;
      width: 2em;
      position: absolute;
      top: 0.5em;
      left: 1em; }
      @media (min-width: 960px) {
        .region-subnav .block-views .submenu-back {
          display: none; } }

@media (min-width: 960px) {
  #tabs {
    margin-left: 272px; } }

.region-top-nowrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #686a6c; }

.view-basic-lists:not(.recommended) .view-header .page-title {
  padding: 2.5em 5em 2em;
  background-color: #fff;
  font-size: 1.125rem; }

.view-basic-lists:not(.recommended) .view-filters {
  display: none; }

.view-basic-lists:not(.recommended) .view-empty {
  margin-left: 1em; }
  @media (min-width: 960px) {
    .view-basic-lists:not(.recommended) .view-empty {
      margin-left: 5em; } }

.view-basic-lists:not(.recommended) .view-content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  width: 100%;
  padding: 0 1em;
  background-color: #686a6c; }
  @media (min-width: 960px) {
    .view-basic-lists:not(.recommended) .view-content {
      padding: 0 4em; } }

.view-basic-lists:not(.recommended) .block-common-cta {
  width: 390px;
  height: calc(192px + 145px);
  margin: 1em; }

.view-basic-lists:not(.recommended) .basic-card {
  margin: 1em; }

.views-filter-block {
  position: relative;
  z-index: 9; }
  .views-filter-block.no-filter form {
    display: none; }
  .views-filter-block .view-header .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    z-index: -1; }
    .views-filter-block .view-header .background.desktop {
      display: none; }
      @media (min-width: 690px) {
        .views-filter-block .view-header .background.desktop {
          display: block; } }
    @media (min-width: 690px) {
      .views-filter-block .view-header .background.mobile {
        display: none; } }
  .views-filter-block > .view-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    position: relative;
    padding: 2.5em 1em 2em; }
    @media (min-width: 960px) {
      .views-filter-block > .view-content {
        padding: 2.5em 5em 2em; } }
    .views-filter-block > .view-content .page-title {
      margin-bottom: 1em;
      color: #fff;
      font-size: 1.125rem; }
    .views-filter-block > .view-content #filters-button {
      transition: none; }
      .views-filter-block > .view-content #filters-button .up-icon {
        display: none; }
      .views-filter-block > .view-content #filters-button.is-active .down-icon {
        display: none; }
      .views-filter-block > .view-content #filters-button.is-active .up-icon {
        display: block; }
    .views-filter-block > .view-content .filters-panel {
      display: flex;
      flex-flow: column nowrap;
      position: absolute;
      top: calc(100% - 2em - 1px);
      right: 0;
      left: 0;
      background-color: #fff;
      max-height: 0;
      pointer-events: none;
      overflow: hidden;
      opacity: 0;
      z-index: -1; }
      .views-filter-block > .view-content .filters-panel.is-active {
        max-height: 1000px;
        border-color: #686a6c;
        pointer-events: auto;
        opacity: 1; }
    .views-filter-block > .view-content .filters {
      padding: 2em 1em 0; }
      @media (min-width: 960px) {
        .views-filter-block > .view-content .filters {
          padding: 2em 5em 0; } }
    .views-filter-block > .view-content .actions {
      padding: 0 1em 1.25em; }
      @media (min-width: 960px) {
        .views-filter-block > .view-content .actions {
          padding: 0 5em 1.25em; } }
    .views-filter-block > .view-content .filters,
    .views-filter-block > .view-content .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start; }
      .views-filter-block > .view-content .filters .filter-item-wrap,
      .views-filter-block > .view-content .actions .filter-item-wrap {
        width: 278px;
        min-height: 64px;
        margin: 0 0.75em 0.75em; }
      .views-filter-block > .view-content .filters .filter-item,
      .views-filter-block > .view-content .filters .action-item,
      .views-filter-block > .view-content .actions .filter-item,
      .views-filter-block > .view-content .actions .action-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.75em 1em;
        font-size: 1.125rem;
        cursor: pointer;
        transition: all 300ms; }
        .views-filter-block > .view-content .filters .filter-item input,
        .views-filter-block > .view-content .filters .action-item input,
        .views-filter-block > .view-content .actions .filter-item input,
        .views-filter-block > .view-content .actions .action-item input {
          display: none; }
        .views-filter-block > .view-content .filters .filter-item svg,
        .views-filter-block > .view-content .filters .action-item svg,
        .views-filter-block > .view-content .actions .filter-item svg,
        .views-filter-block > .view-content .actions .action-item svg {
          flex-shrink: 0;
          height: 1em;
          width: 1em;
          fill: #686a6c; }
        .views-filter-block > .view-content .filters .filter-item .check,
        .views-filter-block > .view-content .filters .action-item .check,
        .views-filter-block > .view-content .actions .filter-item .check,
        .views-filter-block > .view-content .actions .action-item .check {
          display: none;
          fill: #92262a; }
        .views-filter-block > .view-content .filters .filter-item.is-active .plus,
        .views-filter-block > .view-content .filters .action-item.is-active .plus,
        .views-filter-block > .view-content .actions .filter-item.is-active .plus,
        .views-filter-block > .view-content .actions .action-item.is-active .plus {
          display: none; }
        .views-filter-block > .view-content .filters .filter-item.is-active .check,
        .views-filter-block > .view-content .filters .action-item.is-active .check,
        .views-filter-block > .view-content .actions .filter-item.is-active .check,
        .views-filter-block > .view-content .actions .action-item.is-active .check {
          display: block; }
      .views-filter-block > .view-content .filters .filter-item,
      .views-filter-block > .view-content .actions .filter-item {
        border: 1px solid #686a6c;
        color: #686a6c; }
      .views-filter-block > .view-content .filters .action-item,
      .views-filter-block > .view-content .actions .action-item {
        justify-content: center;
        text-transform: uppercase; }
        .views-filter-block > .view-content .filters .action-item.apply,
        .views-filter-block > .view-content .actions .action-item.apply {
          background-color: #376291;
          color: #fff; }
        .views-filter-block > .view-content .filters .action-item.clear,
        .views-filter-block > .view-content .actions .action-item.clear {
          border: 1px solid #92262a;
          color: #92262a; }

.views-filter-block .view-content .filters .filter-item.is-active,
.no-touchevents .views-filter-block .view-content .filters .filter-item:hover {
  border-color: #92262a;
  color: #92262a; }

.view-home-content .view-content {
  display: flex;
  flex-flow: row wrap; }
  .view-home-content .view-content .block-common-cta {
    height: 325px; }
  .view-home-content .view-content > div {
    border-width: 0;
    border-color: #fff;
    border-style: solid;
    /* START responsive widths and borders */
    /* END responsive widths and borders */ }
    .view-home-content .view-content > div:nth-child(1) h3,
    .view-home-content .view-content > div:nth-child(2) h3 {
      font-size: 2.375rem; }
    @media (max-width: 689px) {
      .view-home-content .view-content > div {
        width: 100%;
        border-width: 0.313em 0; }
        .view-home-content .view-content > div:nth-child(1) {
          border-width: 0 0 0.313em; }
        .view-home-content .view-content > div:last-child {
          border-width: 0.313em 0 0; } }

@media (min-width: 690px) and (max-width: 959px) {
  .view-home-content .view-content > div:nth-child(1) {
    width: 100%; }
  .view-home-content .view-content > div:nth-child(2) {
    width: 100%; }
  .view-home-content .view-content > div:nth-child(4n+3) {
    width: 60%; }
  .view-home-content .view-content > div:nth-child(4n+4) {
    width: 40%; }
  .view-home-content .view-content > div:nth-child(4n+5) {
    width: 40%; }
  .view-home-content .view-content > div:nth-child(4n+6) {
    width: 60%; }
  .view-home-content .view-content > div:nth-child(5) {
    width: 50%; }
  .view-home-content .view-content > div:nth-child(6) {
    width: 50%; }
  .view-home-content .view-content > div:nth-child(1) {
    border-width: 0 0 0.313em; }
  .view-home-content .view-content > div:nth-child(2) {
    border-width: 0.313em 0; }
  .view-home-content .view-content > div:nth-child(2n+3) {
    border-width: 0.313em 0.313em 0.313em 0; }
  .view-home-content .view-content > div:nth-child(2n+4) {
    border-width: 0.313em 0 0.313em 0.313em; }
  .view-home-content .view-content > div:nth-last-child(2) {
    border-bottom-width: 0; }
  .view-home-content .view-content > div:last-child {
    border-bottom-width: 0; } }

@media (min-width: 960px) and (max-width: 1099px) {
  .view-home-content .view-content > div {
    width: 100%;
    border-width: 0.313em 0; }
    .view-home-content .view-content > div:nth-child(5) {
      width: 50%; }
    .view-home-content .view-content > div:nth-child(6) {
      width: 50%; }
    .view-home-content .view-content > div:first-child {
      border-top-width: 0; }
    .view-home-content .view-content > div:nth-child(5) {
      border-width: 0.313em 0.313em 0.313em 0; }
    .view-home-content .view-content > div:nth-child(6) {
      border-width: 0.313em 0 0.313em 0.313em; }
    .view-home-content .view-content > div:last-child {
      border-bottom-width: 0; } }

@media (min-width: 1100px) and (max-width: 1399px) {
  .view-home-content .view-content > div:nth-child(1) {
    width: 50%; }
  .view-home-content .view-content > div:nth-child(2) {
    width: 50%; }
  .view-home-content .view-content > div:nth-child(4n+3) {
    width: 60%; }
  .view-home-content .view-content > div:nth-child(4n+4) {
    width: 40%; }
  .view-home-content .view-content > div:nth-child(4n+5) {
    width: 40%; }
  .view-home-content .view-content > div:nth-child(4n+6) {
    width: 60%; }
  .view-home-content .view-content > div:nth-child(1) {
    border-width: 0 0.313em 0.313em 0; }
  .view-home-content .view-content > div:nth-child(2) {
    border-width: 0 0 0.313em 0.313em; }
  .view-home-content .view-content > div:nth-child(2n+3) {
    border-width: 0.313em 0.313em 0.313em 0; }
  .view-home-content .view-content > div:nth-child(2n+4) {
    border-width: 0.313em 0 0.313em 0.313em; }
  .view-home-content .view-content > div:nth-last-child(2) {
    border-bottom-width: 0; }
  .view-home-content .view-content > div:last-child {
    border-bottom-width: 0; } }
    @media (min-width: 1400px) {
      .view-home-content .view-content > div:nth-child(1) {
        width: 50%; }
      .view-home-content .view-content > div:nth-child(2) {
        width: 50%; }
      .view-home-content .view-content > div:nth-child(6n+3) {
        width: 30%; }
      .view-home-content .view-content > div:nth-child(6n+4) {
        width: 40%; }
      .view-home-content .view-content > div:nth-child(6n+5) {
        width: 30%; }
      .view-home-content .view-content > div:nth-child(6n+6) {
        width: 37.5%; }
      .view-home-content .view-content > div:nth-child(6n+7) {
        width: 25%; }
      .view-home-content .view-content > div:nth-child(6n+8) {
        width: 37.5%; }
      .view-home-content .view-content > div:nth-child(1) {
        border-width: 0 0.313em 0.313em 0; }
      .view-home-content .view-content > div:nth-child(2) {
        border-width: 0 0 0.313em 0.313em; }
      .view-home-content .view-content > div:nth-child(3n+3) {
        border-width: 0.313em 0.313em 0.313em 0; }
      .view-home-content .view-content > div:nth-child(3n+4) {
        border-width: 0.313em; }
      .view-home-content .view-content > div:nth-child(3n+5) {
        border-width: 0.313em 0 0.313em 0.313em; }
      .view-home-content .view-content > div:nth-last-child(3) {
        border-bottom-width: 0; }
      .view-home-content .view-content > div:nth-last-child(2) {
        border-bottom-width: 0; }
      .view-home-content .view-content > div:last-child {
        border-bottom-width: 0; } }

.recommended {
  margin-top: 7em;
  padding: 0 1em; }
  .recommended .view-header {
    text-align: center;
    border-bottom: 1.5px solid #92262a;
    margin-bottom: 1em;
    padding-bottom: .25em; }
    @media (min-width: 520px) {
      .recommended .view-header {
        width: 16.75em;
        text-align: left;
        margin-left: .5em; } }
  @media (min-width: 520px) {
    .recommended .view-content {
      display: flex;
      flex-flow: row wrap; } }
  .recommended .recommend-card {
    margin-bottom: 2em; }
    @media (min-width: 520px) {
      .recommended .recommend-card {
        flex-basis: 50%;
        padding: 0 .5em; } }
    @media (min-width: 960px) {
      .recommended .recommend-card {
        flex-basis: 33%; } }
  .recommended .views-field-title {
    text-align: center;
    margin-top: 1em; }
    @media (min-width: 520px) {
      .recommended .views-field-title {
        text-align: left; } }

.view-single-basic-post > .view-content {
  display: flex;
  flex-flow: column nowrap; }

.view-single-basic-post .header {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  position: relative;
  padding: 3em 2em 2em;
  z-index: 0; }
  @media (min-width: 960px) {
    .view-single-basic-post .header {
      padding: 3em 4em 1em; } }
  .view-single-basic-post .header .title {
    max-width: 100%; }
  .view-single-basic-post .header .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    z-index: -1; }
    @media (min-width: 690px) {
      .view-single-basic-post .header .background.mobile {
        display: none; } }
    .view-single-basic-post .header .background.desktop {
      display: none; }
      @media (min-width: 690px) {
        .view-single-basic-post .header .background.desktop {
          display: block; } }
  .view-single-basic-post .header .topic {
    margin-bottom: 0.5em;
    cursor: default;
    color: #fff;
    border: 1px solid #fff; }
    .view-single-basic-post .header .topic.induction-heating {
      background-color: #2a4b6f; }
    .view-single-basic-post .header .topic.infrared-curing {
      background-color: #92262a; }
    .view-single-basic-post .header .topic.electric-forklifts {
      background-color: #607a43; }
    .view-single-basic-post .header .topic.pipeline {
      background-color: #ebb220; }
    .view-single-basic-post .header .topic.electric-vehicles {
      background-color: #00a7b5; }
  .view-single-basic-post .header .media-type {
    font-weight: 600; }
