.basic-card {
  @include flex(column);
  width: $basic-card-width;
  max-width: 100%;

  .top {
    @include flex(row, nowrap, flex-end);
    height: $basic-card-top-height;
    position: relative;
    padding: 1.25em;
    z-index: 0;

    a {
      @include position(absolute, 0 0 0 0);
      border-bottom: 0;
    }
  }

  .bottom {
    @include flex(column, nowrap, flex-start, space-between);
    padding: 1.25em;
    height: $basic-card-bottom-height;
    background-color: color("white");
  }

  .background {
    @include position(absolute, 0 0 0 0);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .video-play-icon {
    @include position(absolute, 20% null null 50%);
    transform: translateX(-50%);
  }

  .title {
    // max-width because IE11 is dumb
    max-width: 100%;
    height: 82px;
    // intentional pixel line-height for line-clamp polyfill
    line-height: 27px;
    overflow: hidden;

    > * {
      font-weight: 400;
    }
  }

  .tag {
    font-weight: 600;
  }
}
