#menu-link-home,
#contact-link-home {
  position: relative;
  padding: 1.6em 1.2em 1.5em 1.1em;

  a {
    @include position(absolute, 0);
    opacity: 0;
    z-index: 9;
  }

  span {
    font-size: $h4;
    font-weight: 600;
  }
}

#menu-link-home {
  border-top: 1px solid color("nevada");
}

#contact-link-home {
  border-bottom: 1px solid color("nevada");
}

#block-block-10 {
  flex-grow: 1;
}

#block-system-main-menu {
  .menu {
    @include flex(column, nowrap, flex-start);
    margin: 0;
    padding: 0;

    .leaf {
      list-style: none;

      a {
        @include position(absolute, 0);
        opacity: 0;
        z-index: 9;
      }
    }
  }

  li {
    @include flex(row, nowrap, center, space-between);
    width: 100%;
    position: relative;
    padding: 1em 1.2em 1em 0.8em;
    border-bottom: 1px solid color("nevada");
    margin-left: 0;
    font-size: $h4;
    font-weight: 600;
    text-transform: uppercase;

    &:first-child {
      border-top: 1px solid color("nevada");
    }

    svg {
      @include size(1em);
      fill: color("white");
      transform: translateX(0);
      transition: transform 300ms;
    }

    &.is-active {
      background-color: color("tuna");
    }

    &:hover {
      svg {
        transform: translateX(0.5em);
      }
    }
  }
}

#block-system-main-menu li,
#menu-link-home,
#contact-link-home {
  span {
    border-bottom: 4px solid transparent;
    text-transform: uppercase;
    transition: border-color 300ms;
  }

  &:hover {
    span {
      border-color: color("thunderbird");
    }
  }
}
