.region-header {
  height: $header-height;
  @include position(fixed, 0 0 null 0);
  background-color: color("abbey");
  z-index: 9;

  @include media(">=tablet") {
    display: none;
  }

  .block, .content {
    @include size(100%);
  }

  .content {
    @include flex(row, nowrap, center, space-between);
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(35px);
    position: relative;
    margin-left: 1em;
    cursor: pointer;

    .lines,
    .lines::before,
    .lines::after {
      width: 100%;
      height: 3px;
      background-color: color("white");
    }

    .lines {
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
      }

      &::before {
        top: 8px;
      }

      &::after {
        top: 24px;
      }
    }
  }

  .close {
    display: none;
    align-items: center;
    justify-content: center;
    @include size(35px);
    position: relative;
    margin-left: 1em;
    cursor: pointer;

    .lines {
      @include size(100%);

      &::before,
      &::after {
        content: "";
        width: 100%;
        height: 3px;
        @include position(absolute, 50% null null 0);
        background-color: color("white");
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &.is-open {
    .hamburger { display: none; }
    .close     { display: flex; }
  }

  .logo {
    padding-right: 0.5em;

    a {
      border: 0;
    }

    img {
      width: 132px;
      height: 40px;
    }
  }
}
