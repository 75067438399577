// standard border width
$m: 0.313em;

.view-home-content .view-content {
  @include flex(row, wrap);

  .block-common-cta {
    height: 325px;
  }

  > div {
    border-width: 0;
    border-color: color("white");
    border-style: solid;

    // tweak font sizes for first two, larger cards
    &:nth-child(1) h3,
    &:nth-child(2) h3 {
      font-size: $h2;
    }

    /* START responsive widths and borders */
    // note we target each individual breakpoint and only apply the styles
    // to that specific breakpoint. this is so we aren't fighting the lower
    // breakpoint's styles in the wider breakpoints.
    @include media("<mobile-lg") {
      width: 100%;
      @include border-width($m 0 $m 0);

      // first item border
      &:nth-child(1) { @include border-width(0 0 $m 0); }
      // last item border
      &:last-child { @include border-width($m 0 0 0); }
      // call-to-action
      // &.block-common-cta {
      //   width: 390px;
      //   margin-left: auto;
      // }
    }

    @include media(">=mobile-lg", "<tablet") {
      // specific nth-child widths
      &:nth-child(1)    { width: 100%; }
      &:nth-child(2)    { width: 100%; }
      &:nth-child(4n+3) { width: 60%; }
      &:nth-child(4n+4) { width: 40%; }
      &:nth-child(4n+5) { width: 40%; }
      &:nth-child(4n+6) { width: 60%; }
      &:nth-child(5)    { width: 50%; }
      &:nth-child(6)    { width: 50%; }
      // specific nth-child borders
      &:nth-child(1)      { @include border-width(0 0 $m 0); }
      &:nth-child(2)      { @include border-width($m 0 $m 0); }
      &:nth-child(2n+3)   { @include border-width($m $m $m 0); }
      &:nth-child(2n+4)   { @include border-width($m 0 $m $m); }
      &:nth-last-child(2) { border-bottom-width: 0 }
      &:last-child        { border-bottom-width: 0 }
    }

    @include media(">=tablet", "<desktop-sm") {
      width: 100%;
      @include border-width($m 0 $m 0);

      // specific nth-child widths
      &:nth-child(5) { width: 50%; }
      &:nth-child(6) { width: 50%; }
      // specific nth-child borders
      &:first-child  { border-top-width: 0; }
      &:nth-child(5) { @include border-width($m $m $m 0); }
      &:nth-child(6) { @include border-width($m 0 $m $m); }
      &:last-child   { border-bottom-width: 0; }
    }

    @include media(">=desktop-sm", "<desktop-lg") {
      // specific nth-child widths
      &:nth-child(1)    { width: 50%; }
      &:nth-child(2)    { width: 50%; }
      &:nth-child(4n+3) { width: 60%; }
      &:nth-child(4n+4) { width: 40%; }
      &:nth-child(4n+5) { width: 40%; }
      &:nth-child(4n+6) { width: 60%; }
      // specific nth-child borders
      &:nth-child(1)      { @include border-width(0 $m $m 0); }
      &:nth-child(2)      { @include border-width(0 0 $m $m); }
      &:nth-child(2n+3)   { @include border-width($m $m $m 0); }
      &:nth-child(2n+4)   { @include border-width($m 0 $m $m); }
      &:nth-last-child(2) { border-bottom-width: 0; }
      &:last-child        { border-bottom-width: 0; }
    }

    @include media(">=desktop-lg") {
      // specific nth-child widths
      &:nth-child(1)    { width: 50%; }
      &:nth-child(2)    { width: 50%; }
      &:nth-child(6n+3) { width: 30%; }
      &:nth-child(6n+4) { width: 40%; }
      &:nth-child(6n+5) { width: 30%; }
      &:nth-child(6n+6) { width: 37.5%; }
      &:nth-child(6n+7) { width: 25%; }
      &:nth-child(6n+8) { width: 37.5%; }
      // specific nth-child borders
      &:nth-child(1)      { @include border-width(0 $m $m 0); }
      &:nth-child(2)      { @include border-width(0 0 $m $m); }
      &:nth-child(3n+3)   { @include border-width($m $m $m 0); }
      &:nth-child(3n+4)   { border-width: $m; }
      &:nth-child(3n+5)   { @include border-width($m 0 $m $m); }
      &:nth-last-child(3) { border-bottom-width: 0; }
      &:nth-last-child(2) { border-bottom-width: 0; }
      &:last-child        { border-bottom-width: 0; }
    }
    /* END responsive widths and borders */
  }
}
