.block-common-cta {
  @include flex(column, nowrap, flex-start);
  background-color: color("white");

  img {
    max-width: 100%;
  }

  .cta-header {
    width: 100%;
    padding: 0.25em 0;
    background-color: color("oslo-gray");
    font-size: $h3;
    font-weight: 600;
    text-align: center;
  }

  .cta-body {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(100%);
    position: relative;
    padding: 0.5em;
    z-index: 0;
    overflow: hidden;
  }

  .cta-background {
    @include size(100%);
    @include position(absolute, 0 null null 0);
    padding: 0.5em;
    object-fit: cover;
    z-index: -1;
  }

  .cta-image {
    height: 100%;
    object-fit: contain;
    // IE11 Polyfill
    font-family: "object-fit: contain;";

    &.cover {
      object-fit: cover;
      // IE11 Polyfill
      font-family: "object-fit: cover;";
    }
  }
}
