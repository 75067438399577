.node-social-feed {
  .page-title {
    padding: 2.5em 5em 2em;
    background-color: color("white");
    font-size: $h5-alt;
  }

  .content {
    @include flex(row, wrap, flex-start, center);
    width: 100%;
    padding: 0 1em;
    background-color: color("nevada");

    @include media(">=tablet") {
      padding: 0 4em;
    }
  }

  .block-common-cta {
    width: $basic-card-width;
    height: calc(#{$basic-card-top-height} + #{$basic-card-bottom-height});
    margin: 1em;
  }

  .basic-card {
    margin: 1em;
  }

  .paginator {
    @include flex(column, nowrap, center, center);
    width: 100%;
    margin-top: 1em;

    a {
      border-bottom: 0;

      &.disabled {
        pointer-events: none;

        .button {
          background-color: color("nevada");
        }
      }
    }

    .button {
      margin-bottom: 1em;
    }

    @include media(">=mobile-lg") {
      @include flex(row, nowrap, flex-start, space-between);
      flex-direction: row;
      width: 70%;
      margin: 1em 0;
    }
  }
}
