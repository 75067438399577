.region-sidebar-left {
  @include flex(column, nowrap, flex-start);
  width: $sidebar-width;
  @include position(fixed, 0 null 0 0);
  margin-top: $header-height;
  background-color: color("abbey");
  color: color("white");
  z-index: 29;
  transform: translateX(-100%);
  transition: transform 300ms;

  @include media(">=tablet") {
    margin-top: 0;
    border-right: 1px solid color("nevada");
    transform: translateX(0);
  }

  &.is-active {
    transform: translateX(0);
  }

  .block {
    width: 100%;
  }

  #block-block-4 {
    @include media(">=tablet") {
      margin-bottom: 1.5em;
    }

    .logo {
      width: 175px;
      // height here makes IE11 happy - it's dumb
      height: 53px;
      margin: 1.5em 0 0 1.5em;
      display: none;

      a {
        border-bottom: 0;
      }

      @include media(">=tablet") {
        display: inline;
      }

      img {
        width: 30%;
        margin-left: .5em;
      }
    }
  }

  .block-search {
    height: 2.5em;
    position: relative;
    border: 2px solid color("oslo-gray");
    background-color: color("nevada");
    border-radius: 1em;
    margin: 2em .5em;
    width: 90%;

    @include media(">=tablet") {
      border-top: 0;
    }

    .content,
    form,
    form > div,
    .form-text {
      @include size(100%);
    }

    form > div {
      @include flex(row, nowrap, center);
      padding-left: 1.5em;
    }

    .form-item {
      flex-grow: 1;
      margin: 0;

      input {
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .form-text {
      border: 0;
    }

    .icon {
      margin-right: 1em;
      pointer-events: none;

      svg {
        @include size(1.35em);
      }

      svg,
      use > svg {
        fill: color("white");
        transition: fill 300ms;
      }
    }

    &.is-active .icon {
      pointer-events: auto;
      cursor: pointer;

      svg,
      use > svg {
        fill: color("white");
      }
    }
  }

  #block-menu-menu-footer {
    padding-bottom: 1em;
  }
}
