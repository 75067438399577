/// Gets flexbox and its associated properties into a one-line mixin
///
/// @param {Flex Direction} $direction [row]
///   CSS flex-direction property
///
/// @param {Flex Wrap} $wrap [nowrap]
///   CSS flex-wrap property
///
/// @param {Flex Alignment} $align [stretch]
///   CSS align-items property
///
/// @param {Flex Justification} $justify [flex-start]
///   CSS justify-content property
///
/// @param {Flex Alignment} $content [stretch]
///   CSS align-content property
///
/// @example scss - Usage
///   .element {
///     @include flex(column, nowrap, flex-start);
///   }
///
/// @example css - CSS Output
///   .element {
///     display: flex;
///     flex-flow: column nowrap;
///     align-items: flex-start;
///   }

@mixin flex($direction: row, $wrap: nowrap, $align: stretch, $justify: flex-start, $content: stretch) {
  display: flex;

  @if $direction != row or $wrap != nowrap {
    flex-flow: $direction $wrap;
  }

  @if $align != stretch {
    align-items: $align;
  }

  @if $justify != flex-start {
    justify-content: $justify;
  }

  @if $content != stretch {
    align-content: $content;
  }
}

/// Provides a directional margin for grid layouts, dropping the margin on the
/// last-child element
///
/// @param {Direction} $direction [right]
///   CSS directional property (top, right, bottom, left)
///
/// @param {Size} $value [0.5em]
///   CSS size property (em, rem, px)
///
/// @example scss - Usage
///   .element {
///     > div {
///       @include grid-margin(right, 1em);
///     }
///   }
///
/// @example css - CSS output
///   .element > div {
///     margin-right: 1em;
///   }
///   .element > div:last-child {
///     margin-right: 0;
///   }
@mixin grid-margin($direction: right, $value: 0.5em) {
  margin-#{$direction}: $value;

  &:last-child {
    margin-#{$direction}: 0;
  }
}
