#block-menu-menu-footer {
  .menu {
    @include flex(row, nowrap, center, center);
    margin: 0;
    padding: 0;

    .leaf {
      margin: 0 0.75em;
      padding: 0;
      font-size: $h6;
      font-weight: 600;
      list-style: none;
    }
  }
}
