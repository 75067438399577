.region-content {
  flex-grow: 1;
  @include flex(column, nowrap);
  width: 100%;
  margin-top: $header-height;

  @include media(">=tablet") {
    margin-top: 0;
    margin-left: $sidebar-width;
  }

  .block-system {
    flex-grow: 1;
    background-color: color("nevada");

    .content {
      background: color("white");
    }
  }
}

.page-user,
.view-basic-lists {
  .region-content,
  .view-content {
    min-height: 100vh;
  }
}
