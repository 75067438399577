.region-sidebar-right {
  padding: 0.75em 1em;

  @include media(">=desktop-sm") {
    padding-right: 0;
  }

  @include media(">=desktop-sm") {
    width: 345px;
  }

  @include media(">=desktop-lg") {
    width: auto;
  }

  .block-views {
    width: 100%;
  }

  .block-common-cta {
    width: 390px;
    max-width: 100%;
    margin-bottom: 1.5em;
  }

  .basic-card {
    margin: 0 0 1em 0;
  }

  .label {
    margin-bottom: 0.5em;
    font-size: $h3-alt;
    text-transform: uppercase;
  }
}
