button,
.button,
.button-primary {
  @include flex(row, nowrap, center, center);
  padding: 0.8em 1.2em;
  border: 0;
  background-color: color("chambray");
  font-size: 1.125em;
  font-weight: 600;
  color: color("white");
  text-transform: uppercase;
  cursor: pointer;
  transition: all 300ms;

  span {
    flex-grow: 1;
  }

  svg {
    @include size(1em);
    margin-left: 0.25em;
    fill: color("white");
  }

  &.white {
    color: color("white") !important;
  }

  &.hollow {
    padding: 0.8em 4em;
    border: 1px solid color("burnt-umber");
    background-color: color("white");
    color: color("burnt-umber");
  }

  &.topic {
    padding: 0.6em 0.7em;
    background-color: color("nevada");
    color: color("white");
    border: 1px solid color("white");
    font-size: 1em;
    text-transform: none;

    &.induction-heating {
      background-color: color("san-juan");
    }

    &.infrared-curing {
      background-color: color("burnt-umber");
    }

    &.electric-forklifts {
      background-color: color("dingley");
    }

    &.pipeline {
      background-color: color("pesto");
    }

    &.electric-vehicles {
      background-color: color("bondiblue");
    }
  }

  &.filter {
    justify-content: space-between;
    width: 270px;
    padding: 0.8em 0.7em;
    border: 0;
    background-color: color("white");
    color: color("nevada");
    font-size: $h5;
    font-weight: 400;
    text-transform: none;

    svg {
      @include size(1.25em);
      margin-left: 0;
      fill: color("nevada");
    }
  }
}

.no-touchevents .button:not(.topic):not(.filter):not(.hollow):hover {
  background-color: lighten(color("burnt-umber"), 10%);
}

.no-touchevents .button.hollow:hover {
  background-color: color("burnt-umber");
  color: color("white");
}
