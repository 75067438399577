.region-subnav {
  width: $sidebar-width;
  @include position(fixed, 0 null 0 0);
  margin-top: $header-height;
  z-index: 99;
  transform: translateX(-100%);
  transition: transform 300ms;

  @include media(">=tablet") {
    margin-top: 0;
    z-index: 19;
    transform: translateX(0);
  }

  .block-views {
    @include flex();
    @include position(absolute, 0 0 0 0);
    padding: 4em 1.75em 0;
    background-color: color("tuna");
    transform: translateX(0);
    transition: transform 300ms;
    overflow-y: auto;
    text-transform: uppercase;

    @include media(">=tablet") {
      padding: 14.5em 1.75em 0;
    }

    &.is-active {
      transform: translateX(100%);
      z-index: 99;
    }

    .view-content,
    .view-footer {
      a {
        font-family: inherit;
        font-size: $h5-alt;
        line-height: 1.222em;
      }
    }

    .view-content > div,
    .view-footer {
      margin-bottom: 1.5em;
    }

    .submenu-back {
      @include size(2em);
      @include position(absolute, 0.5em null null 1em);

      @include media(">=tablet") {
        display: none;
      }
    }
  }
}
