.view-basic-lists:not(.recommended) {
  .view-header {
    .page-title {
      padding: 2.5em 5em 2em;
      background-color: color("white");
      font-size: $h5-alt;
    }
  }

  .view-filters {
    display: none;
  }

  .view-empty {
    margin-left: 1em;

    @include media(">=tablet") {
      margin-left: 5em;
    }
  }

  .view-content {
    @include flex(row, wrap, flex-start, center);
    align-content: flex-start;
    width: 100%;
    padding: 0 1em;
    background-color: color("nevada");

    @include media(">=tablet") {
      padding: 0 4em;
    }
  }

  .block-common-cta {
    width: $basic-card-width;
    height: calc(#{$basic-card-top-height} + #{$basic-card-bottom-height});
    margin: 1em;
  }

  .basic-card {
    margin: 1em;
  }
}
