.node-basic-post {
  @include flex(column);

  @include media(">=desktop-sm") {
    flex-direction: row;
    padding-right: 2em;
  }

  @include media(">=desktop-lg") {
    padding-right: 4em;
  }

  .main {
    @include flex(column, nowrap, flex-start);
    padding: 0 1em;
    background-color: color("white");

    @include media(">=tablet") {
      padding: 0 2em 0 4em;
    }

    @include media(">=desktop-sm") {
      flex: 1 1 0;
    }
  }

  .copy {
    @include flex(column, nowrap, flex-start);
    width: 100%;
    margin-bottom: 3em;
  }

  .field {
    margin-top: 2em;
  }

  .field-label {
    margin-bottom: 1em;
  }

  .field-name-field-date-published {
    align-self: flex-end;
    margin: 0 2em 1em 0;
    font-weight: 300;
  }

  .blog-content {
    max-width: 100%;

    @include media(">=mobile") {
      display: flex;
    }
  }

  .attached-doc {
    @include media(">=mobile") {
      margin-left: 220px;
    }
  }

  .authors {
    width: 70%;
    max-width: 100%;
    margin: 0 0 1em;

    @include media(">=mobile") {
      margin-right: 1em;

      .field {
        margin-top: 0.4em;
      }

      .field-name-field-author-image {
        width: 220px;
        max-width: 100%;
      }
    }

    @include media(">=mobile-lg") {
      width: 35%;
    }

    @include media(">=tablet") {
      width: 38%;
      max-width: 250px;
      flex-direction: row;
    }
  }

  .field-name-body {
    max-width: 100%;
    margin-top: 0;
  }

  .field-name-field-topic,
  .field-name-field-media-type {
    display: none;
  }

  .share {
    flex-basis: 4em;
    @include flex();
    margin-top: 1em;

    > div {
      @include flex(row, nowrap, center, center);
      @include size(3em);
      position: relative;
      @include grid-margin();
      background-color: color("nevada");
      cursor: pointer;
      transition: transform 300ms;
      border-radius: 1em;

      @include media(">=desktop-sm") {
        @include size(2em);
        border-radius: .7em;
      }

      svg {
        @include size(2em);
        fill: color("white");

        @include media(">=desktop-sm") {
          @include size(1.5em);
        }
      }

      a {
        @include flex(row, nowrap, center, center);
        @include position(absolute, 0 0 0 0);
        border: 0 !important;
        background-color: color("thunderbird");
        border-radius: 1em;

        @include media(">=desktop-sm") {
          border-radius: .7em;
        }
      }

      &:first-child { z-index: 1; }
      &:nth-child(2) { transform: translateX(-100%) translateX(-0.5em); }
      &:nth-child(3) { transform: translateX(-200%) translateX(-1em); }
      &:nth-child(4) { transform: translateX(-300%) translateX(-1.5em); }
      &:nth-child(5) { transform: translateX(-400%) translateX(-2em); }
      &:nth-child(6) { transform: translateX(-500%) translateX(-2.5em); }
      &:nth-child(7) { transform: translateX(-600%) translateX(-3em); }
    }

    &.is-active {
      > div {
        transform: translateX(0);
      }
    }
  }


}
