.views-filter-block {
  position: relative;
  z-index: 9;

  &.no-filter {
    form { display: none; }
  }

  .view-header {
    .background {
      @include position(absolute, 0);
      background-size: cover;
      background-position: center center;
      z-index: -1;

      &.desktop {
        display: none;

        @include media(">=mobile-lg") {
          display: block;
        }
      }

      &.mobile {
        @include media(">=mobile-lg") {
          display: none;
        }
      }
    }
  }

  > .view-content {
    @include flex(column, nowrap, flex-start);
    position: relative;
    padding: 2.5em 1em 2em;

    @include media(">=tablet") {
      padding: 2.5em 5em 2em;
    }

    .page-title {
      margin-bottom: 1em;
      color: color("white");
      font-size: $h5-alt;
    }

    #filters-button {
      transition: none;

      .up-icon {
        display: none;
      }

      &.is-active {
        .down-icon { display: none; }
        .up-icon   { display: block; }
      }
    }

    .filters-panel {
      @include flex(column);
      @include position(absolute, calc(100% - 2em - 1px) 0 null 0);
      background-color: color("white");
      max-height: 0;
      pointer-events: none;
      overflow: hidden;
      opacity: 0;
      z-index: -1;

      &.is-active {
        max-height: 1000px;
        border-color: color("nevada");
        pointer-events: auto;
        opacity: 1;
      }
    }

    // padding is on the individual elements because putting it on the parent
    // makes the max-height transition break.
    .filters {
      padding: 2em 1em 0;

      @include media(">=tablet") {
        padding: 2em 5em 0;
      }
    }

    .actions {
      padding: 0 1em 1.25em;

      @include media(">=tablet") {
        padding: 0 5em 1.25em;
      }
    }

    .filters,
    .actions {
      @include flex(row, wrap, flex-start);

      .filter-item-wrap {
        width: 278px;
        min-height: 64px;
        margin: 0 0.75em 0.75em;
      }

      .filter-item,
      .action-item {
        @include flex(row, nowrap, center, space-between);
        padding: 0.75em 1em;
        font-size: $h5-alt;
        cursor: pointer;
        transition: all 300ms;

        input {
          display: none;
        }

        svg {
          flex-shrink: 0;
          @include size(1em);
          fill: color("nevada");
        }

        .check {
          display: none;
          fill: color("burnt-umber");
        }

        &.is-active {
          .plus  { display: none; }
          .check { display: block; }
        }
      }

      .filter-item {
        border: 1px solid color("nevada");
        color: color("nevada");
      }

      .action-item {
        justify-content: center;
        text-transform: uppercase;

        &.apply {
          background-color: color("chambray");
          color: color("white");
        }

        &.clear {
          border: 1px solid color("burnt-umber");
          color: color("burnt-umber");
        }
      }
    }
  }
}

.views-filter-block .view-content .filters .filter-item.is-active,
.no-touchevents .views-filter-block .view-content .filters .filter-item:hover {
  border-color: color("burnt-umber");
  color: color("burnt-umber");
}
