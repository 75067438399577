.modal {
  @include flex(row, nowrap, center, center);
  @include position(fixed, 0 0 0 0);
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
  transition: opacity 300ms;

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }

  .modal-overlay {
    @include position(absolute, 0 0 0 0);
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 0);
    z-index: -1;
  }

  .modal-copy {
    @include size(40%);
    position: relative;
    background-color: color("white");
    transform: scale(0.75, 0.75);
    transition: transform 300ms;
  }

  &.is-active .modal-copy {
    transform: scale(1, 1);
  }

  .modal-close {
    @include position(absolute, 0 0 null null);
    cursor: pointer;

    svg {
      @include size(3em);
      fill: color("nevada");
    }
  }
}
