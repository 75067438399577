/* =Typography
----------------------------------------------- */

// custom font!
@font-face {
  font-family: 'DIN';
  src:    url('../../fonts/D-DIN.woff2') format('woff2'),
          url('../../fonts/D-DIN.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DIN';
  src:    url('../../fonts/D-DIN-Italic.woff2') format('woff2'),
          url('../../fonts/D-DIN-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'DIN';
  src:    url('../../fonts/D-DIN-Bold.woff2') format('woff2'),
          url('../../fonts/D-DIN-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

// links
a {
  border-bottom: 1px solid transparent;
  text-decoration: none;
  color: inherit;
  transition: border-color 300ms;
}

li a.active {
  color: inherit;
}

// color definitions
.typography.light {
  color: color("nevada");
}

.typography.light a {
  color: color("chambray");
}

.typography.light a.is-active,
.no-touchevents .typography.light a:hover {
  border-color: color("chambray");
}

.typography.dark {
  color: color("white");
}

.typography.dark a {
  color: color("white");
  border-color: transparent;
  border-width: 4px;
}

.typography.dark a.is-active,
.no-touchevents .typography.dark a:hover {
  border-color: color("thunderbird");
}

// lists
ul {
  padding-left: 1.3em;
}

li {
  padding-bottom: 1em;
}

// font styling and sizing
div, p, small, h1, h2, h3, h4, h5, h6, li, button, svg text {
  color: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, li, div {
  font-family: $body-font;
}

p, li {
  font-size: $p;
}

p {
  margin-top: 0;
  margin-bottom: 0.7em;
  line-height: 1.4em;

  &:last-child {
    margin-bottom: 0;
  }
}

small {
  display: block;
  font-size: modular-scale(-2);
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font;
  margin: 0;
  font-weight: 600;
}

h1 { font-size: $h1; font-weight: 400; }
h2 { font-size: $h2; }
h3 { font-size: $h3; }
h4 { font-size: $h4; }
h5 { font-size: $h5; }
h6 { font-size: $h6; }

button, .button {
  font-family: $button-font;
}

input {
  font-family: $body-font;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: color("white");
}
::-moz-placeholder { /* Firefox 19+ */
  color: color("white");
}
:-ms-input-placeholder { /* IE 10+ */
  color: color("white");
}
:-moz-placeholder { /* Firefox 18- */
  color: color("white");
}

.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.no-line-height {
  line-height: 0;
}

// positional helper classes
.text-center { text-align: center; }
.text-left   { text-align: left; }
.text-right  { text-align: right; }
.text-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// text styling helper classes
.w-xlight    { font-weight: 200; }
.w-light     { font-weight: 300; }
.w-regular   { font-weight: 400; }
.w-semibold  { font-weight: 600; }
.w-bold      { font-weight: 700; }
.w-xbold     { font-weight: 800; }
.uppercase   { text-transform: uppercase; }
.lowercase   { text-transform: lowercase; }
.capitalize  { text-transform: capitalize; }
.italic      { font-style: italic; }
.underline   { text-decoration: underline; }

// super / sub script
.sub-script {
  position: relative;
  top: 4px;
  font-size: 60%;
}

// shadows
.text-shadow {
  text-shadow: 0 5px 12px rgba(0, 0, 0, 0.45);
}

.text-shadow-alt {
  text-shadow: 0 5px 8px rgba(0, 0, 0, 0.45);
}
