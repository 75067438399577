.social-icons {
  @include flex();

  > div {
    @include flex(row, nowrap, center, center);
    @include size(3em);
    position: relative;
    @include grid-margin(right, 0.6em);
    border: 1px solid color("purple-pizzazz");
    background-color: color("white");
    transition: background-color 300ms;
  }

  svg {
    @include size(1.2em);
    fill: color("purple-pizzazz");
    transition: fill 300ms;
  }

  // youtube a bit bigger icon
  .youtube svg {
    @include size(1.4em);
  }

  a {
    @include position(absolute, 0 0 0 0);
    border-bottom: 0;
  }
}

.no-touchevents .social-icons > div:hover {
  background-color: color("purple-pizzazz");

  svg {
    fill: color("white");
  }
}
