.block-energy-conversion {
  background-color: color("white");
  .info {
    margin: 1em auto;
    padding: 0 1em;
  }

  #energy-title {
    position: relative;
    display: flex;
    align-items: flex-end;
    background-image: url("/sites/all/themes/aephub/img/energy-conversion.png");
    padding: 1em;

    @include media(">=mobile-lg") {
      height: 224px;
    }

    @include media(">=desktop-sm") {
      height: 255px;
      padding: 1em 1.5em;
    }

    #energy-tag {
      margin-bottom: 0;
      color: color("white");
      font-size: 1.25rem;
      font-style: italic;
      font-weight: 600;
    }

    h1 {
      width: 50%;
      color: color("white");
      text-transform: uppercase;
      font-weight: 600;

      @include media(">=mobile-lg") {
        font-size: 4rem;
        line-height: 0.875em;
      }
    }

    #energy-icon {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      width: 4em;
      opacity: 0.75;

      @include media(">=mobile-lg") {
        width: 5em;
      }
    }
  }
}
