.page-user #block-system-main,
.node-webform {
  @include flex(row, nowrap, center, center);
  background-color: color("white");

  form {
    padding-top: 6em;
  }

  form > div {
    @include flex(column, nowrap);
    border: 2px solid color("chambray");
    padding: 2em;

    h2 {
      order: -1;
    }

    input:not([type=checkbox]) {
      width: 100%;
    }

    input[type=submit] {
      width: 30%;
    }
  }
}
