.node-page {
  @include flex();

  @include media(">=tablet") {
    padding-right: 4em;
  }

  .content {
    @include flex(column, nowrap, flex-start);
    padding: 0 2em 3em;
    background-color: color("white");

    @include media(">=tablet") {
      padding: 0 2em 3em 4em;
    }

    h2 {
      margin: 1em 0;
    }
  }
}
