.recommended {
  margin-top: 7em;
	padding: 0 1em;

	.view-header {
		text-align: center;
		border-bottom: 1.5px solid color("burnt-umber");
		margin-bottom: 1em;
		padding-bottom: .25em;

		@include media(">=mobile-sm") {
			width: 16.75em;
			text-align: left;
			margin-left: .5em;
		}
	}

	.view-content {
		@include media(">=mobile-sm") {
			display: flex;
			flex-flow: row wrap;
		}
	}

	.recommend-card {
		margin-bottom: 2em;

		@include media(">=mobile-sm") {
			flex-basis: 50%;
			padding: 0 .5em;
		}

		@include media(">=tablet") {
			flex-basis: 33%;
		}
	}

	.views-field-title {
		text-align: center;
		margin-top: 1em;

		@include media(">=mobile-sm") {
			text-align: left;
		}
	}
}
