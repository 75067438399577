html,
body {
  @include size(100%);
}

body {
    overflow-x: hidden;

    // hides the svg sprite
    > svg {
      display: none;
    }
}

// imagery
img {
  max-width: 100%;
  height: auto;
}

// REMOVES THE STATEMENT ABOUT NO CONTENT
#first-time { display: none; }

.page-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @include size(100%);
}

.admin-menu {
  .region-sidebar-left {
    top: 29px;
  }
}

// form elements
input {
  outline: 0;
}

// responsive videos
.youtube-embed-wrapper,
.videodetector {
  height: 0;
  position: relative;
  padding-top: 0.25em;
  padding-bottom: 56.25%;
  margin-bottom: 2em;

  iframe {
    @include size(100%);
    @include position(absolute, 0 null null 0);
  }
}

// user page
.page-user {
  .page-wrapper {
    flex-direction: column;
  }
}

// tables
table {
  margin-top: 1em;

  tbody {
    border-top: 0;
  }

  td {
    display: block;
    padding: 0 2em;
    vertical-align: top;

    @include media(">=mobile-sm") {
      display: table-cell;
    }

    ul {
      margin: 0;
    }
  }
}
