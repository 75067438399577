.video-play-icon {
  padding: 0.5em;
  border-radius: 50%;
  border: 2px solid color("white");
  fill: color("white");
}

.home-card .video-play-icon {
  @include size(90px);
}

.basic-card .video-play-icon {
  @include size(80px);
  background-color: rgba(0, 0, 0, 0.2);
}
