.view-single-basic-post {
  > .view-content {
    @include flex(column);
  }

  .header {
    @include flex(column, nowrap, flex-start);
    width: 100%;
    position: relative;
    padding: 3em 2em 2em;
    z-index: 0;

    @include media(">=tablet") {
      padding: 3em 4em 1em;
    }

    .title {
      max-width: 100%;
    }

    .background {
      @include position(absolute, 0 0 0 0);
      background-size: cover;
      background-position: center center;
      z-index: -1;

      &.mobile {
        @include media(">=mobile-lg") {
          display: none;
        }
      }

      &.desktop {
        display: none;

        @include media(">=mobile-lg") {
          display: block;
        }
      }
    }

    .topic {
      margin-bottom: 0.5em;
      cursor: default;
      color: color("white");
      border: 1px solid color("white");

      &.induction-heating {
        background-color: color("san-juan");
      }

      &.infrared-curing {
        background-color: color("burnt-umber");
      }

      &.electric-forklifts {
        background-color: color("dingley");
      }

      &.pipeline {
        background-color: color("fuel-yellow");
      }

      &.electric-vehicles {
        background-color: color("bondiblue");
      }
    }

    .media-type {
      font-weight: 600;
    }
  }
}
